import { motion } from 'framer-motion'

const TextHover = ({ children, from, to }) => {
  const variants = {
    from,
    to,
  }
  return (
    <motion.div className="TextHover" initial="from" whileHover="to" variants={variants}>
      {children}
    </motion.div>
  )
}

TextHover.defaultProps = {
  from: {},
  to: {},
}
TextHover.propTypes = {}

export default TextHover
