import { createContext, useContext, useState } from 'react'

export const PageContext = createContext({})

const PageContextProvider = ({ children, value }) => {
  const [pageData, setPageData] = useState({})
  const ctx = {
    data: pageData,
    setData: d => setPageData(d),
  }
  return <PageContext.Provider value={ctx}>{children}</PageContext.Provider>
}

export const usePage = (key = false) => {
  const ctx = useContext(PageContext)
  return key ? ctx[key] : ctx
}

export default PageContextProvider
