import Inner from '~/components/Ui/Inner'
import Row from '~/components/Ui/Grid/Row'
import Column from '~/components/Ui/Grid/Column'

const Debug = ({ columns }) => {
  const cols = []
  for (let i = 1; i <= columns; i++) {
    cols.push(
      <Column key={i} size={`1/${columns}`} className="Column">
        <div className="Column-Inner">{`${i}/${columns}`}</div>
      </Column>
    )
  }

  return (
    <div className="Grid-Debug">
      <Inner>
        <Row>{cols}</Row>
      </Inner>
    </div>
  )
}

Debug.defaultProps = {
  columns: 14,
}
Debug.propTypes = {}

export default Debug
