import classnames from 'classnames'
import { useDebug } from '~/context/debug'

const mapInput = (str = '', prefix = '') =>
  str
    .split(' ')
    .filter(s => s !== '')
    .map(s => `${prefix}${s}`)

const Spacer = ({ scale, vertical }) => {
  const debug = useDebug()
  const gutters = mapInput(scale, vertical ? 'sv-' : 'sh-')

  return (
    <div
      className={classnames(gutters, {
        debug: debug?.spacer,
      })}
    >
      {debug?.spacer && scale}
    </div>
  )
}

Spacer.defaultProps = {
  vertical: true,
  scale: '10',
}
Spacer.propTypes = {}

export default Spacer
