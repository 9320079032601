import classNames from 'classnames'
import Text from '~/components/Ui/Text'
import Icon from '~/components/Ui/Icon'

const Button = ({ as: Tag, type, textType, icon, children, className, small, ...tagProps }) => (
  <>
    {!icon && (
      <Tag className={classNames('Button', `type-${type}`, className, { small })} {...tagProps}>
        <div className="background" />
        <div className="button--content">
          <Text as="div" type={textType} color="none">
            {children}
          </Text>
        </div>
      </Tag>
    )}

    {icon && (
      <Tag className={classNames('Button', 'Button-Icon', `type-${type}`, className)}>
        <div className="background" />
        <div className="button--content">
          <Icon type={icon} color="none" />
        </div>
      </Tag>
    )}
  </>
)

Button.defaultProps = {
  as: 'button',
  type: 'brand',
  textType: 'button',
  icon: null,
  small: false,
}
Button.propTypes = {}

export default Button
