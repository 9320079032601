import { isSSR, isDevelopment } from '~/utils/helpers'
import consent from '~/services/consent'
import config from '~/config'

const tracker = () => {
  const namespace = 'probots'
  let consentGiven = false
  const debug = false
  let queue = []
  const gtm = isSSR() ? [] : (window.dataLayer = window.dataLayer || [])

  const track = (payload, ignoreConsent = false) => {
    if (isSSR()) {
      return
    }

    if (!ignoreConsent && !consentGiven) {
      queue.push(payload)
      debug && console.log('[[ queued ]]', payload)
      return
    }

    if (!isDevelopment()) {
      debug && console.log('[[ track ]]', payload)
      gtm.push(payload)
    } else {
      debug && console.log('[[ debug ]]', payload)
    }
  }

  const send = (evt, payload = {}, ignoreConsent = false) => {
    if (isSSR()) {
      return
    }
    const name = [namespace, evt].join('.')

    track({ event: name, payload }, ignoreConsent)
  }

  const setVariable = payload => {}

  const go = () => {
    const state = consent.state()

    Object.keys(state).forEach(evt => {
      if (state[evt]) {
        track({ consent: { [evt]: true } })
      } else {
        track({ consent: { [evt]: false } })
      }
    })

    consentGiven = true

    queue.forEach(p => track(p))
    queue = []
  }

  const init = () => {
    // debug && track(['enableDebugMode'], true)

    consent.on('done', go)
  }

  const event = (category = null, action = null, name = '', value = 0) => {
    if (!category || !action) {
      debug && console.log('[[ ignored ]]', { category, action, name, value })
      return
    }

    send('event', {
      event_category: category,
      event_action: action,
      event_name: name,
      event_value: value,
    })
  }

  return {
    init,
    send,
    track,
    event,
  }
}

export default tracker()
