import React, { createContext, useContext, useMemo } from 'react'
import useMediaLib from 'use-media'
import { isSSR } from '~/utils/helpers'

export const MediaQueryContext = createContext(null)

export default function MediaQueryProvider({ children }) {
  const mediaQueries = {
    xs: '(max-width: 767px)',
    sm: '(min-width: 769px)',
    md: '(min-width: 992px)',
    lg: '(min-width: 1200px)',
    xl: '(min-width: 1350px)',
    xxl: '(min-width: 1600px)',
  }

  const xs = useMediaLib(mediaQueries.xs)
  const sm = useMediaLib(mediaQueries.sm)
  const md = useMediaLib(mediaQueries.md)
  const lg = useMediaLib(mediaQueries.lg)
  const xl = useMediaLib(mediaQueries.xl)
  const isTouch = isSSR()
    ? false
    : 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
  const value = useMemo(() => ({ xs, sm, md, lg, xl, isTouch }), [xs, sm, md, lg, xl, isTouch])

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext)
}

export function useMedia(query) {
  const ctx = useContext(MediaQueryContext)
  return ctx[query]
}
