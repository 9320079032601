import { createContext, useContext, useState } from 'react'

export const StateContext = createContext({})

const StateContextProvider = ({ children }) => {
  const [navigation, setNavigation] = useState(false)
  const [cursorType, setCursorType] = useState('default')

  const toggleNavigation = () => {
    setNavigation(!navigation)
  }

  const setCursor = (type = 'default') => {
    setCursorType(type)
  }

  return (
    <StateContext.Provider
      value={{
        navigation,
        toggleNavigation,
        cursorType,
        setCursor,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export const useSiteState = (key = false) => {
  const ctx = useContext(StateContext)
  return key ? ctx[key] : ctx
}

export default StateContextProvider
