import { useState, useEffect } from 'react'
import useHydrated from '~/hooks/use-hydrated'

export default function useWindowSize() {
  const isClient = useHydrated()

  function getSize() {
    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    setWindowSize(getSize())
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', handleResize)
  }, [isClient])

  return windowSize
}
