import { createContext, useContext, useState, useEffect } from 'react'
import { isDevelopment, hasLocalStorage } from '~/utils/helpers'
import config from '~/config'

export const DebugContext = createContext({})

export default function DebugContextProvider({ children }) {
  const [keys, setKeys] = useState(
    isDevelopment()
      ? {
          components: false,
          page: false,
          grid: true,
          spacer: false,
          text: false,
        }
      : {}
  )

  const [saved, setSaved] = useState(true)

  useEffect(() => {
    const local = hasLocalStorage ? JSON.parse(localStorage.getItem('prbts-debug') || null) : null
    local && isDevelopment() && setKeys(local)
  }, [true])

  const set = key => {
    setKeys({ ...keys, ...{ [key]: !keys[key] } })
    setSaved(false)
  }

  useEffect(() => {
    if (!saved && hasLocalStorage) {
      localStorage.setItem('prbts-debug', JSON.stringify(keys))
      setSaved(true)
    }
  }, [saved])

  return <DebugContext.Provider value={{ ...keys, set }}>{children}</DebugContext.Provider>
}

export const useDebug = (key = false) => {
  const ctx = useContext(DebugContext)
  if (!config.debug) {
    return key ? false : {}
  }
  return key ? ctx[key] : ctx
}
