import classNames from 'classnames'

const Center = ({ as, children, className, ...tagProps }) => {
  const Tag = as
  return (
    <Tag className={classNames('Center', className)} {...tagProps}>
      {children}
    </Tag>
  )
}
Center.defaultProps = {
  as: 'div',
}
Center.propTypes = {}

export default Center
