import classNames from 'classnames'

const Inner = ({ children, className }) => (
  <div className={classNames('Inner', className, {})}>{children}</div>
)

Inner.defaultProps = {}
Inner.propTypes = {}

export default Inner
