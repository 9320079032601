import Cache from '~/cache/index.server'
import Lang from '~/lang/index.server'
import config from '~/config'

const generateCacheKey = (method, path, prefix = 'url:') => `${prefix}${path}`

const call = async (method = 'GET', path = '/', payload = {}, cache = false) => {
  const cacheKey = generateCacheKey(method, path, `url:${Lang.getCurrent()}/`)
  const apiUrl = `${config.api.base}${config.api.path}${path}`
  let body = null
  let queryString = ''

  if (method.toUpperCase() === 'GET') {
    const queryParams = new URLSearchParams(payload).toString()
    if (queryParams) {
      queryString = `?${queryParams}`
    }
  }

  if (method.toUpperCase() === 'POST') {
    body = JSON.stringify(payload)
  }

  const cached = await Cache.get(cacheKey)

  if (cached) {
    cached.payload.cache = {
      hit: true,
      key: cacheKey,
    }
    return cached?.payload
  }

  const response = await fetch(apiUrl + queryString, {
    method: method.toUpperCase(),
    body,
  })

  // try {
  const json = await response.json()

  if (json?.success) {
    if (cache) {
      await Cache.set(cacheKey, json)
    }

    json.payload.cache = {
      hit: false,
      key: null,
    }

    return json?.payload
  } else {
    if (json?.error === 'path.not_found') {
      throw new Response('Not Found', {
        status: json?.payload?.status || 404,
        statusText: 'page not found',
      })
    }
  }
  // } catch (e) {
  //   Exception('api.error')
  // }
}

export const get = async (path = '/', query = {}, cache = false) => {
  return await call('GET', path, query, cache)
}
