import { createContext, useContext, useState } from 'react'

export const SiteContext = createContext({})

const SiteContextProvider = ({ children, value }) => {
  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}

export const useSite = (key = false) => {
  const ctx = useContext(SiteContext)
  return key ? ctx[key] : ctx
}

export const useNavigation = (key = false, searchBy = 'slug') => {
  const ctx = useContext(SiteContext)

  return key ? ctx.menus?.data?.find(m => m[searchBy] === key) || false : ctx
}

export const useStrings = () => {
  const ctx = useContext(SiteContext)
  return ctx.strings || []
}

export default SiteContextProvider
