import { redirect } from '@remix-run/cloudflare'
import { get } from '~/services/api'
import Cache from '~/cache/index.server'

const API_PATH = '/redirects' // TODO: add to separate API-Paths-File
const CACHE_KEY = 'http/redirects'

const redirectMiddleware = async ({ request }) => {
  const url = new URL(request.url)
  const redirects = await getRedirects()

  if (redirects?.length === 0) {
    return
  }

  for (const redirect of redirects) {
    // if (!redirect.enabled) {
    //   continue
    // }

    // handle regex redirects
    if (redirect.regex && url?.pathname?.match(redirect.url)) {
      handleRedirect(redirect)
    }

    // handle exact redirects
    if (!redirect.regex && url?.pathname === redirect.url) {
      handleRedirect(redirect)
    }

    // TODO: handle more options of the wordpress redirection plugin
    // source flags: 'flag_case', 'flag_trailing', etc ...
    // match_type: 'login', 'referrer', 'ip', etc ...
  }
}

const handleRedirect = data => {
  switch (data.action_type) {
    case 'url':
      throw redirect(data.action_data.url, data.action_code)
    case 'error':
      // TODO: show 404 page
      break
    case 'random':
      // TODO: redirect to random post
      break
    case 'nothing':
      break
    case 'pass':
      // TODO: no redirect - pass through
      break
  }
}

const getRedirects = async () => {
  let redirects = { items: [] }

  redirects.items = await Cache.get(CACHE_KEY)

  if (redirects.items) {
    return redirects.items
  }

  try {
    redirects = await get(API_PATH, {}, true)
  } catch (e) {}

  if (!redirects || !redirects.items) {
    return []
  }

  await Cache.set(CACHE_KEY, redirects.items)
  return redirects.items
}

export default redirectMiddleware
