import PropTypes from 'prop-types'
import classNames from 'classnames'

const mapInput = (str = '', prefix = '') =>
  str
    .split(' ')
    .filter(s => s !== '')
    .map(s => `${prefix}${s}`)

const Column = ({ children, size, push, pull, className }) => {
  const sizes = mapInput(size, 'u-')
  const pushes = mapInput(push, 'u-push-')
  const pulls = mapInput(pull, 'u-pull-')

  return (
    <div className={classNames('Column', 'o-grid__col', className, sizes, pushes, pulls)}>
      {children}
    </div>
  )
}

Column.defaultProps = {
  size: '',
  push: '',
  pull: '',
  children: null,
  className: null,
}

Column.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  push: PropTypes.string,
  pull: PropTypes.string,
  className: PropTypes.string,
}
export default Column
