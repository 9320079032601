import classNames from 'classnames'

const Icon = ({ type, color, colorHover }) => (
  <span
    className={classNames('Icon', `icon-${type}`, `color-${color}`, `color-hover-${colorHover}`)}
  />
)

Icon.defaultProps = {
  type: 'code',
  color: 'dark',
}
Icon.propTypes = {}

export default Icon
