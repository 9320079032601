import PropTypes from 'prop-types'
import classNames from 'classnames'

const Row = ({ children, className, size }) => (
  <div className={classNames('Row', 'o-grid', size ? `o-grid--${size}` : null, className)}>
    {children}
  </div>
)

Row.defaultProps = {
  children: null,
  className: null,
  size: null,
}

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
}
export default Row
