import EventEmitter from 'eventemitter3'
import { hasLocalStorage } from '~/utils/helpers'
// NEXT: update tag manager

export default (() => {
  const events = new EventEmitter()
  let store = 'probots-consent'
  let settings = {
    tag_manager_id: '',
    version: 'probots-consent-1.0.0',
    title: 'Cookies',
    description: 'TBD',
    buttons: {
      save: 'Speichern',
      accept_all: 'Alle Akzeptieren',
      deny_all: 'Alle ablehnen',
      settings: 'Einstellungen',
    },
  }
  let user = {}

  const providers = {}
  const categories = {}
  const flat = []

  const keyed = (category, slug) => [category, slug].join('.')

  const init = config => {
    store = config.store || store
    settings = { ...settings, ...config.settings }

    const providersByCategory = {}

    Object.keys(config.providers).forEach(idx => {
      const provider = { ...config.providers[idx] }

      if (!provider.active) {
        return
      }

      if (!provider.cookies) {
        provider.cookies = []
      }

      if (!providersByCategory[provider.category]) {
        providersByCategory[provider.category] = []
      }

      if (provider.checked) {
        user[keyed(provider.category, provider.slug)] = true
      }

      providers[provider.slug] = provider

      providersByCategory[provider.category].push(provider)
    })

    Object.keys(config.categories).forEach(idx => {
      const category = { ...config.categories[idx] }
      category.providers = providersByCategory[category.slug]

      if (category.providers?.length > 0) {
        if (category.checked) {
          user[category.slug] = true
        }
        flat.push(category)
      }

      categories[category.slug] = category
    })

    events.emit('init', flat)
    events.emit('user', { ...user })

    local()
  }

  const local = () => {
    const data = hasLocalStorage
      ? JSON.parse(localStorage.getItem(getSetting('version'))) || {}
      : {}

    if (!data?.consent) {
      events.emit('show')
      return
    }

    user = { ...user, ...data.consent, ...{ seen: true } }

    events.emit('user', { ...user })
    events.emit('done')
  }

  const on = (evt, cb) => events.on(evt, cb)

  const off = (evt, cb) => events.off(evt, cb)

  const toggle = (category, provider = null) => {
    let key = provider ? keyed(category, provider) : category

    user[key] = user[key] ? false : true

    if (!provider) {
      if (categories[category].editable === false) {
        return
      }

      Object.keys(providers).forEach(slug => {
        const provider = providers[slug]

        if (provider.category === category) {
          user[keyed(category, slug)] = user[key]
        }
      })
    }

    if (provider && !user[key]) {
      if (providers[provider].editable === false) {
        return
      }
      user[category] = false
    }

    events.emit('change')
    events.emit('user', { ...user })
  }

  const all = (bool = true) => {
    if (!bool) {
      user = {}
    }

    if (bool) {
      Object.keys(providers).forEach(slug => {
        const provider = providers[slug]
        user[provider.category] = true
        user[keyed(provider.category, slug)] = true
      })
    }

    events.emit('user', { ...user })
    save()
  }

  const has = (category, provider = null) => {
    const key = provider ? keyed(category, provider) : category

    return user[key]
  }

  const save = () => {
    events.emit('save')

    user.seen = true

    if (hasLocalStorage) {
      localStorage.setItem(
        getSetting('version'),
        JSON.stringify({
          settings: {
            saved: new Date(),
          },
          consent: user,
        })
      )
    }

    events.emit('done')
  }

  const reset = () => {
    if (hasLocalStorage) {
      localStorage.removeItem(getSetting('version'))
    }
    user = {}
  }

  const getData = () => flat
  const getUser = () => user
  const getSetting = k => settings[k]
  const getState = () => {
    const state = {}
    Object.values(providers).forEach(provider => {
      const key = keyed(provider.category, provider.slug)
      state[provider.slug] = user[key] || false
    })
    return state
  }
  const hasSeen = () => user.seen || false
  const show = () => events.emit('show')

  return {
    init,
    on,
    off,
    toggle,
    has,
    all,
    save,
    reset,
    data: getData,
    user: getUser,
    state: getState,
    setting: getSetting,
    show,
    seen: hasSeen,
  }
})()
