import classNames from 'classnames'
import { nl2br as nl2brFunc } from '~/utils/helpers'

const Text = ({
  as,
  type,
  color,
  colorHover,
  center,
  className,
  children,
  html,
  nowrap,
  underline,
  nl2br,
  ...tagProps
}) => {
  const Tag = as

  if (html) {
    const formatted = nl2brFunc(children)
    return (
      <Tag
        className={classNames(
          'Text',
          `type-${type}`,
          `color-${color}`,
          `color-hover-${colorHover}`,
          {
            center,
            nowrap,
            underline,
          },
          className
        )}
        {...tagProps}
        dangerouslySetInnerHTML={{ __html: formatted }}
      />
    )
  }

  return (
    <Tag
      className={classNames(
        'Text',
        `type-${type}`,
        `color-${color}`,
        `color-hover-${colorHover}`,
        {
          center,
          nowrap,
          underline,
        },
        className
      )}
      {...tagProps}
    >
      {children}
    </Tag>
  )
}

Text.defaultProps = {
  as: 'span',
  type: 'default',
  color: 'default',
  colorHover: 'default',
  underline: false,
  nl2br: false,
}
Text.propTypes = {}

export default Text
