import { redirect } from '@remix-run/cloudflare'
import Lang from '~/lang/index.server'
import { get } from '~/services/api'
import config from '~/config'

export const getPage = async (slug, payload = {}) => {
  const currentLang = Lang.getCurrent()

  let data = {}

  if (payload?.preview) {
    const previewId = payload?.preview_id || payload?.p
    data = await get(
      `/preview/${slug || ''}`,
      {
        id: previewId,
      },
      true
    )
  } else {
    data = await get(`/path/${slug || ''}`, payload, true)
  }

  if (config.lang.redirectIfLanguageMismatch && data?.lang?.current !== currentLang) {
    const alternateUrl = data?.lang?.alternate?.[currentLang]?.url?.relative

    if (alternateUrl) {
      throw redirect(alternateUrl, 301)
    }
  }

  return data || {}
}

export const getSite = async () => {
  const site = await get('/site', { lang: Lang.getCurrent() }, true)

  return site
}
