import ReactJson from 'react-json-view-ssr'

const Json = ({ json, name }) => (
  <div className="json">
    <ReactJson
      src={json}
      indentWidth={2}
      collapsed={1}
      name={name}
      enableClipboard={false}
      // theme="grayscale:inverted"
      theme="monokai"
      style={{ fontSize: '10px' }}
    />
  </div>
)

Json.defaultProps = {
  name: 'data',
}
Json.propTypes = {}

export default Json
