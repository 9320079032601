import Text from '~/components/Ui/Text'

const ErrorDisplay = ({ message, title, debug }) => (
  <div className="Error">
    <Text as="div" type="text-l" color="brand">
      {title}
    </Text>
    <Text as="div" type="text-s" color="dark">
      {message}
    </Text>
    {debug}
    {console.log(debug)}
  </div>
)

ErrorDisplay.defaultProps = {
  title: 'Oh no!',
}
ErrorDisplay.propTypes = {}

export default ErrorDisplay
